.services_container {
    margin: 0;
}


/* saftey assurance */

.saftey {
    margin-top: 6em;
    display: grid;
    place-items: center;
    width: 99vw;
}

.saftey_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: #F5F7F2;
    width: 95%;
    height: 25em;
    border-radius: 7px;
}

.saftey_header {
    padding: 0 2em 0 2em;
}

.saftey_header h1,
p {
    font-family: 'Montserrat';
}

.saftey_header h1 {
    color: var(--p-blue);
    font-family: 'Baskervville', serif !important;
}

.saftey_header p {
    color: #000;
    font-weight: 300;
    font-family: 'Montserrat';
}

.saftery_image {
    display: flex;
    align-self: self-end;
}

.saftey_image img {
    width: 130%;
    filter: grayscale(0.5);
    position: absolute;
    top: 0;
    right: 0;
}

.services-content-container {
    font-family: "Montserrat";
    margin: 5em 3em 0 3em;
}

.services-content-header p {
    text-align: left !important;
    width: 50vw;
    color: #000 !important;
}

.services-content {
    font-family: "Montserrat";
    color: #000;
    font-weight: 500;
    width: 90vw;
}

.services-content h1 {
    color: var( --p-blue);
    font-weight: 500;
    font-family: 'Baskervville', serif !important;
}

.services-content h2 {
    font-family: 'Baskervville', serif !important;
    color: #fff !important;
    font-weight: 300;
}

.services-content p {
    font-family: "Montserrat";
    color: #fff;
    font-weight: 300;
}

.sevices-main-content-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.sevices-content-examples {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    margin-bottom: 3em;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.sevices-content-examples-two {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    margin-bottom: 3em;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    padding-top: 10vh;
}

.services-content-sample {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45vw;
    text-align: center;
    grid-gap: 1em;
    font-size: 'Montserrat';
    padding: 1em;
    height: 50vh;
    background: #252525;
    transition: transform 0.5s ease-in-out;
}

.services-content-sample:hover {
    box-shadow: 3px 3px 1px #1d1c1c9c;
    transform: scale(1.1);
}

.services-content-sample:hover {
    box-shadow: 3px 3px 1px #1d1c1c9c;
    transform: scale(1.1);
}

.services-content-sample h2 {
    font-family: 'Baskervville', serif !important;
    font-weight: 600 !important;
}

.services-content-sample-1,
.services-content-sample-4,
.services-content-sample-6 {
    background-color: #000 !important;
}

.services-content-sample-2,
.services-content-sample-4,
.services-content-sample-7 {
    background-color: var(--s-blue) !important;
}

.services-content-sample-4 {
    background-color: #000 !important;
}

@media screen and (max-width: 960px) {
    .saftey {
        margin-top: -6em;
    }
    .services-content {
        padding-top: 3em;
    }
    .services-content h1 {
        text-align: center;
    }
    .services-content-container {
        margin: 2em 0 0 1em;
    }
    .sevices-content-examples {
        grid-template-columns: 1fr;
    }
    .services-content-sample {
        height: 62vh;
    }
    .services-content h2 {
        text-align: center;
    }
    .services-content p {
        text-align: center;
    }
    /* ========================================services intro text */
    .services-content p {
        width: 90vw !important;
    }
    .sevices-main-content-cont {
        flex-direction: column;
    }
    .services-content-sample {
        height: 77vh;
        width: 90vw;
    }
    .sevices-content-examples-two {
        margin-top: 0;
        padding-top: 0;
    }
}

@media (min-width: 385px) and (max-width: 412px) {
    .services-content-sample {
        height: 41vh;
    }
}

@media (min-width: 413px) and (max-width: 414px) {
    .services-content-sample {
        height: 41vh;
    }
}

@media (min-width: 415px) and (max-width: 540px) {
    .services-content-sample {
        height: 41vh;
    }
}