.consultancy-training-content {
    margin-top: 3em;
    padding: 3em;
}

.consultancy-training-content h1,
h2,
h3,
b {
    font-family: 'Montserrat' !important;
    color: var(--p-blue);
}

.consultancy-training-content p {
    font-weight: 300 !important;
}

.consultancy-training-content ul {
    font-weight: 300 !important;
}

@media screen and (max-width: 960px) {
    .consultancy-training-content {
        padding: 5em 1em 0 1em;
        text-align: center;
    }
    .consultancy-training-content h1 {
        font-size: 40px !important;
    }
}