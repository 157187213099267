.dropdown-menu-two {
    width: 15em;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
}

.dropdown-menu-two li {
    padding: -11px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.dropdown-menu-two li a {
    font-size: smaller !important;
}

a.dropdown-link {
    font-size: smaller !important;
}

.dropdown-menu-two li:hover {
    background-color: #781B38;
}

.dropdown-menu-two li a:hover {
    color: #fff !important;
}

.dropdown-menu-two .clicked {
    display: none;
}

.dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #000;
    padding: 16px;
}

@media screen and (max-width: 960px) {
    .dropdown-menu-two {
        display: inline-block !important;
        position: static !important;
    }
}