/* Footer */

.footer {
    margin-top: 5em;
    color: #fff;
    font-family: 'Montserrat';
    background: var(--p-blue);
    padding: 2em 0;
    width: 100%;
}

.footer-logo {
    width: 50%;
    padding: 0;
    margin: 0;
}

.footer-logo img {
    width: 30%;
}

.container.footer_container {
    margin: 0 !important;
    padding: 0 1em;
}

.footer_details_cont {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    width: 96vw;
    padding: 2em;
    border-bottom: 0.5px solid var(--p-green);
}

.footer_detail h3 {
    color: #fff !important;
}

.footer_detail a,
p {
    font-family: 'Montserrat';
    font-weight: 200;
}

.footer_detail a:hover {
    color: #00ff2a;
}

.footer_detail ul {
    margin-top: 15px;
}

.footer_detail ul li {
    padding: 5px 0;
}

.footer_detail.footer_detail_three li {
    padding: 0 !important;
}

.footer_copyright_cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2em;
    width: 80vw;
    padding-left: 2em;
}

.copyright p {
    margin: 0 !important;
}

.footer_socials {
    position: relative;
    left: 5.3em;
}

.footer_socials ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    grid-gap: 1em;
    font-size: 1.5em;
}

.footer_socials svg {
    color: #ffffff;
}

@media screen and (max-width: 960px) {
    .container.footer_container {
        margin: 0 !important;
        padding: 0;
        display: grid;
        align-items: center;
        justify-content: center;
    }
    .footer-logo img {
        display: inline;
    }
    .footer_details_cont {
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
        text-align: center;
        grid-gap: 2em;
        padding: 1em;
        margin: 0;
    }
    .footer_copyright_cont {
        flex-direction: column;
        text-align: center;
        grid-gap: 1em;
    }
    .footer_socials ul {
        display: flex;
        flex-direction: row;
        grid-gap: 1em;
        font-size: 1.5em;
    }
    .footer_socials {
        position: relative;
        left: 0;
    }
}