/* ==================================================HEADER STYLES================================================== */

.header {
    margin-top: 4rem;
    height: 20rem;
    overflow: hidden;
    /* border-bottom: 2px solid var(--color-gray-400); */
}

.header_container {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    background: black;
}

.header_container_bg {
    position: absolute;
    object-fit: contain;
}

.sanitation_header_container_bg img {
    transform: translateY(0em);
    opacity: 0.5;
    object-fit: cover;
    width: 100% !important;
    height: 100vh !important;
    /* position: relative !important;
    top: 9em !important; */
}

.header_content {
    width: 44%;
    margin: 0 auto;
    text-align: center;
    color: var(--p-blue);
}

.header_container h2 {
    margin-bottom: 1rem;
    color: var(--p-blue) !important;
}

.header_content p {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 500;
}

.header_content a {
    font-family: 'Montserrat';
    font-weight: 600;
}