.contact {
    margin-top: 3rem;
    color: var(--p-blue);
}

.contact_container {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: repeat(2, 1fr);
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 0 5em;
}

.contact_wrapper {
    width: fit-content;
    display: flex;
    gap: 3.5rem;
    flex-direction: column;
    margin-left: 3rem;
    position: relative;
    top: 1.85rem;
}

.contact_wrapper_contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.contact_wrapper_contents svg {
    color: var(--p-blue);
}

.contact_wrapper_contents p {
    font-size: 15px;
    font-weight: 500;
    color: var(--p-blue);
}

.contact_wrapper a {
    background: transparent;
    font-size: 2rem;
    border: 1px solid var(--p-blue);
    transition: var(--transion);
    border-radius: 0.5rem;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact_wrapper a:hover {
    background: transparent;
    border-color: var(--p-blue);
}

.form_wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.form_wrapper h4 {
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 5px;
    font-size: 1.5rem;
    font-family: 'Montserrat' !important;
}

form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
}

form input {
    background: transparent;
    color: var(--color-black);
    border: 1px solid var(--p-blue);
    padding: 1rem 0.5rem;
    border-radius: 5px;
    position: relative;
    width: 100%;
}

form textarea {
    background: transparent;
    color: var(--p-blue);
    border: 1px solid var(--p-blue);
    padding: 1rem 0.5rem;
    border-radius: 5px;
    position: relative;
    width: 100%;
}

form button {
    margin: 0 !important;
}

.contact_form_btn:hover {
    background: transparent;
    color: var(--color-white);
    border: 1px solid var(--p-blue);
}

.contact_map_container {
    display: block;
    margin-top: 3.5rem;
    grid-column-start: 1;
    grid-column-end: 3;
}

.contact_map_container h4 {
    margin-bottom: 4rem;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 5px;
    font-size: 1.5rem;
    font-family: 'Montserrat' !important;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {
    .contact_container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .contact_wrapper {
        gap: 4rem;
        flex-direction: row;
        position: relative;
        left: 7rem;
    }
    .contact_wrapper_contents {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .contact_map_container {
        grid-column-start: 1;
        grid-column-end: 1;
    }
}


/* ==============================MEDIA QUERIES (SM)===================================== */

@media screen and (max-width:768px) {
    .container.contact_container {
        margin-top: 9em;
        height: 80em;
    }
}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:600px) {
    .contact_container {
        grid-template-columns: 1fr;
        gap: 3rem;
        margin-top: 0;
        padding-top: 0;
    }
    .contact_wrapper {
        flex-direction: row;
        gap: 1rem;
        position: relative;
        left: 0.5rem;
        margin: 0;
        padding: 0;
    }
    .contact_wrapper_contents p {
        display: none;
    }
    .contact_wrapper_contents {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .contact_map_container {
        margin-top: 0;
        grid-column-start: 1;
        grid-column-end: 1;
    }
}