.dropdown-menu {
    width: 15em;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.dropdown-menu li {
    padding: auto;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

a.dropdown-link {
    font-size: smaller !important;
}

.dropdown-menu li:hover {
    background-color: var(--p-blue);
}

.dropdown-menu li a:hover {
    color: #fff;
}

.dropdown-menu .clicked {
    display: none;
}

.dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #000;
    padding: 16px;
    font-family: 'Montserrat' !important;
    font-weight: 300 !important;
}

@media screen and (max-width: 960px) {
    .dropdown-menu {
        display: inline-block !important;
        position: static !important;
    }
}