@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

:root {
    --p-green: #ffffff;
    --p-blue: #781B38;
    --white: #F7F7F7;
    --s-blue: #79796D;
    --purplered: rgb(153, 1, 255);
    --gray: rgb(48, 48, 48);
    --purple: 160;
    --color-gray-100: hsl(var(--primary-hue), 47%, 94%);
    --color-gray-200: hsl(var(--primary-hue), 26%, 75%);
    --color-gray-300: hsl(var(--primary-hue), 24%, 64%);
    --color-gray-400: hsl(var(--primary-hue), 16%, 41%);
    --color-gray-500: hsl(0, 0%, 24%);
    --color-gray-600: hsl(var(--primary-hue), 48%, 15%);
    --container-width-lg: 80%;
    --container-width-md: 90%;
    --transition: all 500ms ease;
}


/* General styles */

html {
    scroll-behavior: smooth;
    /* background: #F5F6F1; */
}

body {
    font-family: 'Monserrat', 'san-serif';
    font-weight: 100;
    color: #000;
    line-height: 1.7;
    overflow-x: hidden;
}

.container {
    /* width: var(--container-width-lg); */
    max-width: 1920px;
    margin-inline: auto;
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1.2;
    color: var(--color-gray-100);
    font-family: 'Baskervville', serif !important;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

p {
    font-weight: 300 !important;
}

a {
    color: var(--color-black);
    font-family: 'Monserrat';
    text-decoration: none;
}

img {
    object-fit: cover;
    display: block;
    width: 100%;
}

.btn {
    color: #fff;
    font-family: 'Montserrat';
    font-weight: normal;
    width: fit-content;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: var(--p-blue);
    transition: var(--transition);
    border-radius: 0;
}

.btn:hover {
    color: var(--p-blue);
    background: #fff;
    border: 1px solid var(--p-blue);
}

.btn .lg {
    padding: 1.2rem;
    font-size: 1.1rem;
}

.btn .sm {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
}

.btn .sm:hover {
    color: var(--p-blue);
    background: #fff;
    border: 1px solid var(--p-blue);
}