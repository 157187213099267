.about_container {
    margin: 0;
}


/* about component */

.homeAbout {
    margin-top: 8em;
}

.cta-home-btn {
    display: none;
}

.about-pestex {
    font-family: "Montserrat";
    margin: 0 3em 0 3em;
}

.about-content {
    font-family: "Montserrat";
    color: #000;
    font-weight: 500;
    width: 90vw;
    padding: 3em;
}

.about-content h1 {
    font-family: 'Montserrat' !important;
}

.about-content h1 {
    font-family: "Montserrat";
    color: var(--p-blue) !important;
    font-weight: 400;
}

.mission-value {
    font-family: "Montserrat";
    color: var(--p-blue) !important;
    font-weight: 400;
}

.about-content h1 {
    font-family: "Montserrat";
    color: var(--p-blue);
    font-weight: 400;
}

.about-content p {
    font-family: "Montserrat";
    color: #000;
    font-weight: 300;
    font-size: 17px !important;
}


/*  values section*/

.values {
    margin-top: 3em;
    padding: 3em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 2em;
    width: 100vw;
}

.values h2,
h4,
p {
    font-family: 'Montserrat';
}

.values_icon {
    font-size: 2em;
    color: var(--p-blue);
}

.value_detail_one h4 {
    font-weight: 600;
    color: var(--p-blue);
    letter-spacing: 5px;
    font-family: 'Montserrat' !important;
}

.value_detail_one h2 {
    font-weight: bold;
    color: var(--p-blue);
    font-size: 2.3em;
    font-family: 'Montserrat' !important;
}

.value_detail_one p {
    font-weight: 300;
}

.value_detail_two {
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    width: 180vw;
}

.value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background: #ddddddbe;
    padding: 1em;
    border-radius: 5px;
}

.value_details {
    margin-top: 1em;
}

.value_details h4 {
    font-weight: bold;
    color: #000;
    font-family: 'Montserrat' !important;
}

.value_details p {
    font-weight: 300;
}


/* Why us */

.whyus {
    margin-top: 6em;
    width: 99vw;
    font-family: 'Montserrat' !important;
}


/* Testimonials */

.testimonials {
    margin-top: 6em;
    width: 99vw;
}

@media screen and (max-width: 960px) {
    .about-content {
        padding: 0 0 0 1em;
        text-align: center;
    }
    .about-pestex {
        font-family: "Montserrat";
        margin: 7em 0 0 7px;
    }
    .values {
        padding: 0;
        flex-direction: column;
        text-align: center;
    }
    .value_detail_one p {
        font-weight: 300;
        padding: 1em;
    }
    .value_details p {
        padding: 0 9em 0 9em;
    }
}

@media (min-width: 385px) and (max-width: 412px) {
    .value_details p {
        padding: 0 10em 0 10em;
    }
}

@media (min-width: 413px) and (max-width: 414px) {
    .value_details p {
        padding: 0 10em 0 10em;
    }
}

@media (min-width: 415px) and (max-width: 540px) {
    .value_details p {
        padding: 0 15em 0 15em;
    }
}